
import { Vue } from 'vue-class-component'
import axios from '@/plugins/axios'

interface Result {
  data: any
  message: string
}

export default class AccountManagerPage extends Vue {
  name = 'Account Manager Page'
  dialogTitle = ''
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listAccount: any = []
  formValidateAccount: any = {
    _id: '',
    name: ''
  }
  dialogAccount = false

  async created() {
    this.getList()
  }

  async getList() {
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('user/account', {
      headers: this.headers
    })
    if (res) {
      this.listAccount = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleSave(row: any) {
    if (row) {
      this.dialogAccount = true
      this.dialogTitle = 'Edit account'
      this.formValidateAccount._id = row._id
      this.formValidateAccount.name = row.name
    } else {
      this.dialogTitle = 'Add account'
      this.dialogAccount = true
    }
  }
  async save() {
    const refFrorm = this?.$refs['refForm'] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    let res: Result
    if (this.formValidateAccount._id) {
      res = await axios.put('user/account', this.formValidateAccount, {
        headers: this.headers
      })
    } else {
      res = await axios.post('user/account', this.formValidateAccount, {
        headers: this.headers
      })
    }

    if (res) {
      this.$message.success(res.message)
      this.dialogAccount = false
      this.formValidateAccount = {
        name: ''
      }
      this.getList()
    }
  }
}
